<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">{{year}}年度 {{name}} - 追加資料提出</p>
      <hr class="title">
    </div>

    <b-tabs
      class="gas-tab"
      nav-wrapper-class="gas-tab-wrap">
      <b-tab title="申請者情報"
        title-link-class="px-5">
        <v-app-control
          :code="code"/>
      </b-tab>
      <b-tab
        v-for="(page, index) in pages" :key="index">
        <template v-slot:title>
          <span>{{page.name}}</span>
          <img
            v-if="hasPageErr[index]"
            class="errorMark"
            src="@/assets/svg/error.svg">
        </template>
        <v-app-form
          :writable="true"
          :pageIndex="index"
          :assets="assets"
          :downloadOpenFile="downloadOpenFile"
          :downloadFormFile="downloadFormFile"/>
      </b-tab>
    </b-tabs>

    <div class="bottonArea mt-4 mb-3 flex flexCenter">
      <p
        v-show="hasEmpty"
        class="warning">
        未入力の必須項目があります。すべての必須項目に入力後、編集を行ってください。
      </p>
    </div>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">
        前の追加資料
      </b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        @click="goList">
        一覧に戻る
      </b-button>
      <b-button
        @click="save"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">
        編集
      </b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg mr-4 bold">
        次の追加資料
      </b-button>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';
import download from '@/modules/download';
import AppForm from '@/components/gas/parts/Form.vue';
import AttControl from '@/components/cms/attachment/Control.vue';

export default {
  name: 'cmsApplicationsAttachmentEdit',
  components: {
    'v-app-form': AppForm,
    'v-app-control': AttControl,
  },
  data() {
    return {
      base: {},
      applicationId: null,
      applicationTypeId: null,
      year: null,
      name: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      assets: {},
      mailInfo: {},
      close_datetime: null,
      code: null,
    };
  },
  computed: {
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    filterdIdList() {
      return this.$store.state.cmsApplicationSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.indexOf(Number(this.applicationId));
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    hasEmpty() {
      // 入力フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      const formOptionData = this.$store.state.gasApplicationForm.optionDataList;
      const findFormResult = inputs.find((input) => {
        if (formOptionData[input.option_id] && !formOptionData[input.option_id].is_required) {
          return false;
        }
        const noSelect = input.field_answer_id === null;
        const notext = input.field_answer_text === '';
        const noFile = input.uploadedFileKey === '';
        return noSelect && notext && noFile;
      });
      return typeof findFormResult !== 'undefined';
    },
    newStatus() {
      return this.$store.state.cmsApplicationControl.status;
    },
    blockMail() {
      return this.mailInfo.has_invalid_email_address;
    },
    onGoing() {
      if (this.base.status === null) {
        return false;
      }
      return true;
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
  },
  methods: {
    async fetchApptype(appId) {
      const param = {
        appId,
      };
      const response = await api.send('/api/cms/attachments/edit', param)
        .catch((err) => {
          console.error(err);
        });

      if (!response) {
        await this.alert('追加資料のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/applications/attachment/list' });
        return;
      }
      const { base } = response.data;
      this.base = base;
      this.year = base.year;
      this.name = base.name;
      this.status = base.status;
      this.code = base.code;
      this.applicationTypeId = base.id;
      this.close_datetime = base.close_datetime;
      this.assets = response.data.assets;
      this.$store.commit('cmsApplicationControl/updateStatus', base.status);
      this.$store.commit('cmsApplicationControl/updateReason', base.reason);
      const {
        pages,
        userInfo,
        answers,
        applicationId,
        userId,
      } = response.data;
      // ユーザーが削除されていたらからサーバーからnullが来るので空オブジェクトに置き換え
      this.mailInfo = response.data.mailInfo || {};
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setUserId', userId);
      this.$store.commit('gasApplicationForm/setApplicationId', Number(applicationId));
      this.$store.commit('gasApplicationForm/setApplicationTypeId', base.application_type_id);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.$store.commit('userInfo/setDbData', userInfo);
    },
    async save() {
      let msg = '上記の内容で追加資料を編集登録します。';
      if (this.status !== Number(this.newStatus) && !this.blockMail && !this.isDeletedUser && this.onGoing) {
        msg += '\nステータスが変更されているため、申請者にメールの送信と通知の登録が行われます。';
      } else if (this.status !== Number(this.newStatus) && !this.isDeletedUser && this.onGoing) {
        msg += '\nステータスが変更されてますが、申請者へのメール送信がブロックされているため、通知の登録のみが行われます。';
      }

      msg += '\n編集登録してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        apptypeId: this.applicationTypeId,
        appId: this.applicationId,
        userId: this.$store.state.gasApplicationForm.user_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
        control: JSON.stringify(this.$store.state.cmsApplicationControl),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/cms/attachments/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.pageErrs = err.response.data.pageErrs;
          } else {
            await this.alert(err.response.data.msg, false);
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('データを更新しました。');
      this.$store.dispatch('page/onLoading');
      this.$store.commit('gasApplicationForm/init');
      this.$store.commit('gasApplicationForm/setIsCms', true);
      this.hasPageErr = []; // タブのエラーマーク初期化
      const { appId } = this.$route.params;
      this.applicationId = appId;
      await this.fetchApptype(appId);
      this.$store.dispatch('page/offLoading');
    },
    async downloadOpenFile(assetId, assetName) {
      const param = {
        assetId,
      };
      this.$store.dispatch('page/onWaiting');
      const requireBlob = true;
      const response = await api.send('/api/cms/attachments/download/file/form', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, assetName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.applicationId,
        attachment_id: this.base.attachment_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/attachments/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      // 初期化
      this.$store.commit('userInfo/initInputData');
      const newIndex = this.filterdIdListIndex + 1;
      this.$router.push({ path: `/cms/applications/attachment/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      // 初期化
      this.$store.commit('userInfo/initInputData');
      const newIndex = this.filterdIdListIndex - 1;
      this.$router.push({ path: `/cms/applications/attachment/edit/${this.filterdIdList[newIndex]}` });
      this.$store.dispatch('page/onLoading');
      this.applicationId = this.filterdIdList[newIndex];
      await this.fetchApptype(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    setErrors(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    setErrorMarks(userErrs) {
      // ユーザー
      this.hasUserErr = Object.keys(userErrs).length > 0;
      // フォーム
      const { inputs } = this.$store.state.gasApplicationInput;
      Object.keys(this.pages).forEach((index) => {
        const { fields } = this.pages[index];
        const thisPageFieldIdList = Object.keys(fields).map((fieldKey) => {
          return fields[fieldKey].field_id;
        });
        let findFormErr = inputs.find((input) => {
          const searchedIndex = thisPageFieldIdList.indexOf(input.field_id);
          const hasErr = input.errMessages.length > 0;
          return searchedIndex !== -1 && hasErr;
        });
        // 例外的にページにエラーマークをつけたいとき
        if (this.pageErrs.includes(Number(index))) {
          findFormErr = true;
        }
        this.hasPageErr.splice(index, 1, findFormErr);
      });
    },
    goList() {
      // 初期化
      this.$store.commit('userInfo/initInputData');
      this.$router.push({ path: '/cms/applications/attachment/list' });
    },
  },
  // ロード画面
  async created() {
    // 初期化
    this.$store.commit('userInfo/initInputData');
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('gasApplicationForm/setIsCms', true);
    const { appId } = this.$route.params;
    this.applicationId = appId;
    await this.fetchApptype(appId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
