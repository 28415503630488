<template>
  <perfect-scrollbar class="p-4 ContentsBox">

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">申請者氏名</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{userInfo.sei}} {{userInfo.mei}}
      </b-col>
    </b-row>

    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="label">
        <label class="labelText bold">申請番号</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        {{code}}
      </b-col>
    </b-row>
    <div>
    </div>

  </perfect-scrollbar>
</template>

<script>
export default {
  name: 'cmsAttachmentControl',
  components: {
  },
  props: {
    code: { type: String, default: null },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
  }
</style>
